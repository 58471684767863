<template>
    <div class="phi-page-side-panel" :class="{open: isOpen}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PhiPageSidePanel",
    props: {
        open: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isOpen: false
        };
    },

    watch: {
        open(newVal) {
            this.set(newVal);
        }
    },

    mounted() {
        this.set(this.open);

        this.$store.commit("onBackButton", () => {
            if (this.isOpen) {
                this.close();
                return false;
            }
        });
    },

    methods: {
        set(bool) {
            this.isOpen = bool;
        },

        close() {
            return this.set(false);
        },

        toggle() {
            return this.set(!this.isOpen);
        }
    }
}
</script>

<style lang="scss">
.phi-page-side-panel {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100vw;
    height: 100vh;

    overflow: hidden;

    background-color: #f3f3f3;

    transition: transform .18s;
    transform: translateX(100%);

    &.open {
        transform: translateX(0);
    }
}
</style>
