<template>
    <div class="phi-post-editor-settings">
        <div class="phi-post-editor-settings-list" v-if="rules">
            <div v-if="rules.replyTarget && !post.replyTo" class="replyTo">
                <phi-page-side-panel ref="panel">
                    <phi-person-relevance-selector-block
                        :personId="post.author.id"
                        :selection="audienceReply"
                    >
                        <div slot="top-left" @click="sentReplyTarget()">
                            <mu-icon value="arrow_back" @click="$refs.panel.close();"/>
                        </div>
                    </phi-person-relevance-selector-block>
                </phi-page-side-panel>

                <div class="phi-media scheduler">
                    <mu-icon class="phi-media-figure" value="person" v-if="!audienceReply.length"></mu-icon>
                    <mu-icon class="phi-media-figure immediate" value="person" v-else></mu-icon>

                    <div class="phi-media-body" @click="scrollTop()">
                        <div @click="$refs.panel.toggle()">
                            <template v-if="audienceReply.length >= 1 && audienceReply.length <= 3">
                                <span>{{ $t('Responder a') }}: </span>
                                <span class="namelist">
                                    <span v-for="person in audienceReply" :key="person.id">{{ getPersonName(person) }} </span>
                                </span>
                            </template>
                            <template v-else>
                                <span v-if="audienceReply.length">{{ $t("Responder a") }} {{ audienceReply.length }} {{ $t("personas") }}</span>
                                <span v-else>{{ $t("Responder a") }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="rules.bcc && rules.bcc.editable" class="bcc">
                <mu-checkbox
                    native-value="1"
                    name="bcc"
                    v-model="checkedBcc"
                    :label="checkedBcc ? $t('post.settings.recipientsInvisible') : $t('post.settings.recipientsVisible')"
                    uncheck-icon="visibility"
                    checked-icon="visibility_off"
                ></mu-checkbox>
            </div>
            <div v-if="rules.allowReplies && rules.allowReplies.editable">
                <mu-checkbox
                    native-value="1"
                    name="settings-allowed-reply"
                    v-model="checkedAllowReplies"
                    :label="checkedAllowReplies ? $t('post.settings.repliesOn') : $t('post.settings.repliesOff')"
                    uncheck-icon="speaker_notes_off"
                    checked-icon="speaker_notes"
                ></mu-checkbox>
            </div>
            <div v-if="rules.allowRepliesAll && rules.allowRepliesAll.editable">
                <mu-checkbox
                    native-value="1"
                    name="settings-allowed-reply"
                    v-model="checkedAllowRepliesAll"
                    :label="checkedAllowRepliesAll ? $t('post.settings.repliesAllOn') : $t('post.settings.repliesAllOff')"
                    uncheck-icon="speaker_notes_off"
                    checked-icon="speaker_notes"
                ></mu-checkbox>
            </div>
            <div v-if="rules.allowForward && rules.allowForward.editable">
                <mu-checkbox
                    native-value="1"
                    name="settings-allowed-reply"
                    v-model="checkedForward"
                    :label="checkedForward ? $t('post.settings.forwardOn') : $t('post.settings.forwardOff')"
                    uncheck-icon="speaker_notes_off"
                    checked-icon="speaker_notes"
                ></mu-checkbox>
            </div>
            <div v-if="rules.highlight && rules.highlight.editable">
                <mu-checkbox
                    native-value="1"
                    name="tag-highlight"
                    v-model="checkedHighlight"
                    :label="checkedHighlight ? $t('post.settings.highlightOn') : $t('post.settings.highlightOff')"
                    uncheck-icon="dashboard"
                    checked-icon="dashboard"
                ></mu-checkbox>
            </div>
            <div v-if="rules.notify && rules.notify.editable">
                <mu-checkbox
                    native-value="1"
                    v-model="checkedNotify"
                    :label="checkedNotify ? $t('post.settings.notificationsOn') : $t('post.settings.notificationsOff')"
                    uncheck-icon="notifications_off"
                    checked-icon="notifications"
                ></mu-checkbox>
            </div>
            <div v-if="rules.schedulePublishDate">
                <div class="phi-media scheduler">
                    <mu-icon class="phi-media-figure" value="alarm" v-if="publishDate && validatePublishdate"></mu-icon>
                    <mu-icon class="phi-media-figure immediate" value="check_circle" v-if="!publishDate && !validatePublishdate"></mu-icon>

                    <div class="phi-media-body">
                        <label v-text="$t('post.editor.schedule')"></label>
                        <flatpickr v-model="publishDate" :config="publishDateSettings" class="publish-date" :placeholder="$t('post.editor.schedule.now')"></flatpickr>
                    </div>

                    <mu-icon class="phi-media-right" value="alarm_off" v-if="publishDate" @click="publishDate = null"></mu-icon>
                </div>
            </div>
            <div v-if="audienceReply.length == 0 && rules.scheduleExpirationDate && !post.replyTo">
                <div class="phi-media scheduler">
                    <mu-icon class="phi-media-figure" value="alarm" v-if="expirationDate"></mu-icon>
                    <mu-icon class="phi-media-figure immediate" value="check_circle" v-if="!expirationDate"></mu-icon>

                    <div class="phi-media-body">
                        <label v-text="$t('post.editor.schedule.expirationDate')"></label>
                        <flatpickr v-model="expirationDate" :config="expirationDateSettings" class="expiration-date" :placeholder="$t('post.editor.schedule.never')"></flatpickr>
                    </div>

                    <mu-icon class="phi-media-right" value="alarm_off" v-if="expirationDate" @click="expirationDate = null"></mu-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Flatpickr from '../../../Flatpickr.vue';
import PhiPageSidePanel from '@/components/Phi/Page/SidePanel.vue';
import PhiPersonRelevanceSelectorBlock from '@/components/Phi/Person/Relevance/SelectorBlock.vue';

export default {
	i18n: {
        "es": {
            "post.editor.schedule": "Fecha de envío",
            "post.editor.schedule.now": "Inmediato",
            "post.editor.schedule.expirationDate": "Fecha de expiración del mensaje",
            "post.editor.schedule.never": "Nunca"
        },
        "en": {
            "post.editor.schedule": "Shipping date",
            "post.editor.schedule.now": "immediate",
            "post.editor.schedule.expirationDate": "Message expiration date",
            "post.editor.schedule.never": "Never"
        },
        "de": {
            "post.editor.schedule": "Versanddatum",
            "post.editor.schedule.now": "Sofort",
            "post.editor.schedule.expirationDate": "Ablaufdatum der Nachricht",
            "post.editor.schedule.never": "noch nie"
        },
        "fr": {
            "post.editor.schedule": "Date d'expédition",
            "post.editor.schedule.now": "Immédiat",
            "post.editor.schedule.expirationDate": "Date d'expiration des messages",
            "post.editor.schedule.never": "Jamais"
        },
        "it": {
            "post.editor.schedule": "Data di spedizione",
            "post.editor.schedule.now": "Immediato",
            "post.editor.schedule.expirationDate": "Data di scadenza del messaggio",
            "post.editor.schedule.never": "Mai"
        }
	},

    components: {
        Flatpickr,
        PhiPageSidePanel,
        PhiPersonRelevanceSelectorBlock
    },

	props: {
		rules: {
			type: Object,
			required: true
        },

		post: {
			type: Object,
			required: true
        }
    },

    data() {
       
        return {
            checked: {
                bcc: null,
                allowReplies: null,
                allowRepliesAll: null,
                forward:null,
                highlight: null,
                notify: null
            },

			publishDateSettings: {
                enableTime: true,
                dateFormat: 'M j, Y h:i K',
                minDate: new Date().fp_incr(1),
                minuteIncrement: 15,                
                disable: [
                    date => date < new Date()
                ]
			},

            expirationDateSettings: {
                enableTime: true,
                dateFormat: 'M j, Y h:i K'
			},

            audienceReply: this.post.replyTarget ? (this.post.replyTarget == "object" ? this.post.replyTarget.to.people : JSON.parse(this.post.replyTarget).to.people) : [],
        }
    },

    computed: {
        checkedBcc: {
            get() {
                return this.getSetting("bcc");
            },

            set(value) {
                return this.setSetting("bcc", value);
            }
        },


        checkedAllowReplies: {
            get() {
                return this.getSetting("allowed").reply;
            },

            set(value) {
                return this.setSetting("allowed", {
                    reply: value,
                    replyAll: this.getSetting("allowed").replyAll
                });
            }
        },
        checkedAllowRepliesAll: {
            get() {
                return this.getSetting("allowed").replyAll;
            },

            set(value) {
                return this.setSetting("allowed", {
                    reply: this.getSetting("allowed").reply,
                    replyAll: value
                });
            }
        },
        checkedForward: {
            get() {
                return this.getSetting("forward");
            },

            set(value) {
                return this.setSetting("forward",value);
            }
        },


        checkedHighlight: {
            get() {
                return this.post.tags && this.post.tags.indexOf("highlight") >= 0;
            },

            set(value) {
                this.post.tags.splice(this.post.tags.indexOf("highlight"), 1);
                if (value) {
                    this.post.tags.push("highlight");
                }
                this.$emit("input");
            }
        },

        checkedNotify: {
            get() {
                return !this.getSetting("silent");
            },

            set(value) {
                this.setSetting("silent", !value);
            }
        },

        publishDate: {
            get() {
                if (!this.post.publishDate) {
                    return null;
                }
                return typeof this.post.publishDate == "object" ? this.post.publishDate : new Date(parseInt(this.post.publishDate) * 1000);
            },

            set(incomingDate) {
                this.post.publishDate = !incomingDate ? null : incomingDate.getTime() / 1000;
                this.$emit("input");
            }
        },

        expirationDate: {
            get() {
                if (!this.post.expirationDate) {
                    return null;
                }
                return typeof this.post.expirationDate == "object" ? this.post.expirationDate : new Date(parseInt(this.post.expirationDate) * 1000);
            },

            set(incomingDate) {
                this.post.expirationDate = !incomingDate ? null : incomingDate.getTime() / 1000;
                this.$emit("input");
            }
        },

        sanitizedAudienceReply() {
            return this.audienceReply.map(person => {
                if (typeof person.firstname != 'undefined') {
                    this.$set(person, 'firstName', person.firstname);
                    this.$set(person, 'lastName', person.lastname);
                }
                return person;
            });
        },

        validatePublishdate(){
            if (this.post.publishDate == null || this.fixDate(this.post?.publishDate) == this.fixDate(new Date())){

                return false;
            }
            
            return true;
        }
    },

    methods: {
        fixDate(date) {
            let d = new Date(date);
            let month = '' + (d.getMonth() + 1)
            let day = '' + d.getDate()
            let year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        sentReplyTarget() {
            window.scrollTo(0, document.body.scrollHeight);

            this.post.replyTarget = {
				to: {
					people: this.audienceReply
				}
			};
            this.$emit("input");
        },

        scrollTop() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },

        getSetting(settingName) {
            if (!this.post || !this.post.settings) {
                return null;
            }

            return typeof this.post.settings[settingName] !== "undefined" ? this.post.settings[settingName] : null;
        },

        setSetting(settingName, value) {
            if (!this.post || !this.post.settings) {
               this.post.settings = this.buildDefaultSettings();
            }

           this.post.settings[settingName] = value;
           this.$emit("input");
        },

        buildDefaultSettings() {
            let retval = {};

            retval.bcc = this.rules.bcc.default;

            retval.allowed = {
                reply: this.rules.allowReplies.default,
                replyAll: this.rules.allowReplies.default
            };

            if(this.rules.allowRepliesAll){
                retval.allowed = {
                    reply: this.rules.allowReplies.default,
                    replyAll: this.rules.allowRepliesAll.default
                };
            }

            if(this.rules.allowForward){
                retval.forward = this.rules.allowForward.default;

            }

            if (!this.post.tags) {
                this.post.tags = [];
            }
            this.post.tags.splice(this.post.tags.indexOf("highlight"), 1);
            if (this.rules.highlight.default) {
                this.post.tags.push("highlight");
            }

            retval.silent = !this.rules.notify.default;

            return retval;
        },

        getPersonName(person) {
			if (typeof person.firstname != "undefined") {
				return person.firstname + " " + person.lastname;
			}

			if (typeof person.firstName != "undefined") {
				return person.firstName + " " + person.lastName;
			}

			return "?";
		}
    },

    created() {
        if (!this.post.settings) {
            this.$set(this.post, "settings", this.buildDefaultSettings());
        }
    }
}
</script>

<style lang="scss">
.phi-post-editor-settings {
    .scheduler {
        align-items: center;
        padding: 8px 12px;

        label {
            font-size: .8em;
            opacity: .8;
        }

		.phi-media-figure {
			text-align: center;
		}

        .phi-media-right {
            color: #ff5252;
            opacity: .7;
            cursor: pointer;
        }

        .immediate {
            color: green;
        }
    }
    .replyTo {
        cursor: pointer;
    }
}

.phi-post-editor-settings-list {

    & > div:hover {
        background-color: rgba(0, 0, 0, .04);
    }

    .mu-ripple-wrapper {
        top: 4px;
        left: -4px;
    }

    .mu-checkbox-wrapper {
        justify-content: flex-start;
        height: 56px;
        padding: 0 8px;
    }

    .mu-checkbox {
        display: block;
        height: auto;
        line-height: auto;
        font-size: 1em;
    }

    .mu-checkbox-icon {
        margin-left: 12px;
    }

    .mu-checkbox-icon-checked {
        color: #2196f3;
    }
    .mu-checkbox-icon-uncheck {
        color: #ccc;
    }

    .bcc .mu-checkbox-icon-checked {
        color: #ccc;
    }
    .bcc .mu-checkbox-icon-uncheck {
        color: #2196f3;
    }

}
</style>