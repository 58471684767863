<script>
import PhidiasTextUtils from "@/libraries/phidias.js/lib/Text.js";
import PhiTabs from '@/components/Phi/Tabs.vue';
import PhiTab from '@/components/Phi/Tab.vue';
import PhiPersonList from '@/components/Phi/Person/List.vue';
import PhiPersonListItem from '@/components/Phi/Person/ListItem.vue';

import anime from 'animejs';

export default {
    name: "phi-person-relevance-selector-block",
    components: {PhiTabs, PhiTab, PhiPersonList, PhiPersonListItem},

	props: {
        selection: {
			type: Array,
			required: true,
			default: []
        },

        personId: {
			type: String,
			required: true
		}
    },

    data() {
        return {
            fetching: true,
            groups: [],
            peopleCache: [],
            partials: {
                relatives: [],
                sections: [],
                agroups: [],
                egroups: []
            },
            selectedGroup: {id: "none"},
            searchTimeout: null,
            peopleQuery: '',
            rightTop: {horizontal: 'right', vertical: 'top'}
        }
    },

    methods: {
        sanitizePersonObject(person) {
            if (typeof person.firstname == "undefined") {
                person.firstname = person.firstName ? person.firstName : "";
            }

            if (typeof person.lastname == "undefined") {
                person.lastname = person.lastName ? person.lastName : "";
            }

            if (typeof person.lastname2 == "undefined") {
                person.lastname2 = person.lastName2 ? person.lastName2 : "";
            }

            return person;
        },

        createSearchCache(){
            let plain   = [];

            this.groups.forEach(group => {

                if(group.hasOwnProperty("people")){
                    group.people.forEach(person => {
                        person = this.sanitizePersonObject(person);

                        let cachedPerson = plain.find(cache => cache.id === person.id);
                        if(cachedPerson === undefined){
                            let mini = {
                                id: person.id,
                                firstname: person.firstname,
                                lastname: person.lastname,
                                filteredFirstname: PhidiasTextUtils.removeDiacritics(person.firstname.trim().toLowerCase()),
                                filteredLastname: PhidiasTextUtils.removeDiacritics(person.lastname.trim().toLowerCase()),
                                gender: person.gender,
                                avatar: person.avatar,
                                roles: person.roles
                            };

                            plain.push(mini);
                        }else{
                            if(person.hasOwnProperty("roles")){
                                person.roles.forEach(role => {
                                    if(cachedPerson.roles.indexOf(role) < 0){
                                        cachedPerson.roles.push(role);
                                    }
                                });
                            }
                        }

                    });
                }

                if(group.hasOwnProperty("members")){
                    group.members.forEach(person => {
                        person = this.sanitizePersonObject(person);

                        let cachedPerson = plain.find(cache => cache.id === person.id);
                        if(cachedPerson === undefined){
                            let mini = {
                                id: person.id,
                                firstname: person.firstname,
                                lastname: person.lastname,
                                filteredFirstname: PhidiasTextUtils.removeDiacritics(person.firstname.trim().toLowerCase()),
                                filteredLastname: PhidiasTextUtils.removeDiacritics(person.lastname.trim().toLowerCase()),
                                gender: person.gender,
                                avatar: person.avatar,
                                roles: person.roles,
                                readonly: person.readonly ? person.readonly : false
                            };

                            plain.push(mini);
                        }else{
                            if(person.hasOwnProperty("roles")){
                                person.roles.forEach(role => {
                                    if(cachedPerson.roles.indexOf(role) < 0){
                                        cachedPerson.roles.push(role);
                                    }
                                });
                            }
                        }

                        if(person.hasOwnProperty("relatives")){
                            person.relatives.forEach(relative => {
                                relative = this.sanitizePersonObject(relative);

                                let cachedRelative = plain.find(cache => cache.id === relative.id);

                                if(cachedRelative === undefined){
                                    let miniRelative = {
                                        id: relative.id,
                                        firstname: relative.firstname,
                                        lastname: relative.lastname,
                                        filteredFirstname: PhidiasTextUtils.removeDiacritics(relative.firstname.trim().toLowerCase()),
                                        filteredLastname: PhidiasTextUtils.removeDiacritics(relative.lastname.trim().toLowerCase()),
                                        gender: relative.gender,
                                        avatar: relative.avatar,
                                        roles: relative.roles,
                                        readonly: relative.readonly ? relative.readonly : false
                                    };

                                    plain.push(miniRelative);
                                }else{
                                    if(relative.hasOwnProperty("roles")){
                                        relative.roles.forEach(role => {
                                            if(cachedRelative.roles.indexOf(role) < 0){
                                                cachedRelative.roles.push(role);
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }

            });

            this.peopleCache = plain;
        },

        fetchRelatives() {
            return this.$store.state.api
                .get(`v3/people/${this.personId}/relevance/relatives`)
                .then(groups => {
                    this.partials.relatives = groups;
                });
        },

        fetchSections() {
            return this.$store.state.api
                .get(`v3/people/${this.personId}/relevance/sections`)
                .then(groups => {
                    this.partials.sections = groups;
                });
        },

        fetchAcademicGroups() {
            return this.$store.state.api
                .get(`v3/people/${this.personId}/relevance/academicgroups`)
                .then(groups => {
                    this.partials.agroups = groups;
                });
        },

        fetchEmployeeGroups() {
            return this.$store.state.api
                .get(`v3/people/${this.personId}/relevance/employeegroups`)
                .then(groups => {
                    this.partials.egroups = groups;
                });
        },

        getFullArray(){
            return [].concat([{
                        id: "sel",
                        name: this.$t("noun.Checked"),
                        people: [],
                        members: this.selection,
                        type: "system"
                    },{
                        id: "searchResult",
                        name: "",
                        people: [],
                        members: this.selection,
                        type: "system"
                    }])
                    .concat(this.partials.relatives)
                    .concat(this.partials.sections)
                    .concat(this.partials.agroups)
                    .concat(this.partials.egroups);
        },

        selectDefaultGroup(){

            if(this.selection.length > 0){
                return this.groups.find(group => group.id === "sel");
            }else{
                let defaultGroup = this.groups.find(group => {
                    if(group.id != 'searchResult'){
                        let people = 0;
                        if(group.people){
                            people = people + group.people.length;
                        }
                        if(group.members){
                            people = people + group.members.length;
                        }

                        return people > 0;
                    }
                });

                if(defaultGroup){
                    return defaultGroup;
                }else{
                    return this.groups.find(group => group.id === "sel");
                }
            }
        },

        getGroups(){
            let relatives = this.fetchRelatives();
            let sections = this.fetchSections();
            let agroups = this.fetchAcademicGroups();
            let egroups = this.fetchEmployeeGroups();

            Promise.all([relatives, sections, agroups, egroups]).then( () => {
                this.groups = this.getFullArray();
                this.createSearchCache();
                this.selectedGroup = this.selectDefaultGroup();
                this.fetching = false;
            });
        },

        selectGroup(groupId){
            let prevIndex = this.groups.findIndex( grp => grp.id == this.selectedGroup.id);
            let newIndex = 0;

            this.groups.forEach((group, index) => {
                if(group.id === groupId){
                    this.selectedGroup = group;
                    group.selected = true;
                    newIndex = index;
                }else{
                    group.selected = false;
                }
            });

            let useAnimation = true;
            if (typeof device != "undefined") {
                if (device.platform == "iOS") {
                   useAnimation = false;
                }
            }

            if(useAnimation){
                let translateValue = [0, 0];
                translateValue[0] = prevIndex <= newIndex ? 100 : -100;

                anime({
                    targets: '.phi-person-list',
                    translateX: translateValue,
                    opacity: [0, 1],
                    duration: 280,
                    easing: 'easeInOutQuart'
                });
            }
        },

        getGroupPeople(group){

            let people = [];
            if(!group){
                return people;
            }

            if(group.people){
                people = people.concat(group.people);
            }

            if(group.members){
                group.members.forEach(member => {
                    if(!people.some(person => person.id == member.id)){
                        people.push(member);
                    }
                });
            }
            return people;
        },

        isSelected(personId) {
            return this.selection.some(selectedPerson => selectedPerson.id == personId);
        },

        togglePerson(personId) {
            if(this.selectedGroup.id !== 'sel'){
                this.isSelected(personId) ? this.deselectPerson(personId) : this.selectPerson(personId);
            }
        },

        selectPerson(personId) {
            if (this.isSelected(personId)) {
                return;
            }

            let tmpPerson =  this.peopleCache.find(per => per.id == personId);
            this.selection.push(tmpPerson);
        },

        deselectPerson(personId) {
            for (var i = 0; i < this.selection.length; i++) {
                if (this.selection[i].id == personId) {
                    this.selection.splice(i, 1);
                    return;
                }
            }
        },

        distinctRoles(group) {
            var retval = [];
            this.getGroupPeople(group).forEach(member => {

                if (member.roles && member.roles.length) {
                    member.roles.forEach(role => {
                        if (retval.indexOf(role) == -1) {
                            retval.push(role);
                        }
                    });
                }

                if (member.hasOwnProperty('relatives')) {
                    member.relatives.forEach(relative => {
                        if (relative.roles && relative.roles.length) {
                            relative.roles.forEach(relativeRole => {
                                if (retval.indexOf(relativeRole) == -1) {
                                    retval.push(relativeRole);
                                }
                            });
                        }
                    });
                }
            });

            return retval;
        },

        getRelatives(people){
            let relatives = [];
            people.forEach( person => {
                if (person.hasOwnProperty('relatives')) {
                    relatives = relatives.concat(person.relatives);
                }
            });
            return relatives;
        },

        roleIsSelected(people, role) {
            return people
                .filter(person => person.roles && person.roles.indexOf(role) >= 0)
                .concat(this.getRelatives(people)
                    .filter(relative => relative.roles.indexOf(role) >= 0)
                )
                .every( person => this.isSelected(person.id));
        },

        selectWithRole(people, role) {
            people.forEach(person => {
                if (person.roles.indexOf(role) >= 0) {
                    this.selectPerson(person.id);
                }

                if (person.hasOwnProperty('relatives')) {
                    person.relatives.forEach(relative => {
                        if (relative.roles.indexOf(role) >= 0) {
                            this.selectPerson(relative.id);
                        }
                    });
                }
            });
        },

        deselectWithRole(people, role) {
            people.forEach(person => {
                if (person.roles.indexOf(role) >= 0) {
                    this.deselectPerson(person.id);
                }

                if (person.hasOwnProperty('relatives')) {
                    person.relatives.forEach(relative => {
                        if (relative.roles.indexOf(role) >= 0) {
                            this.deselectPerson(relative.id);
                        }
                    });
                }
            });
        },

        toggleWithRole(people, role) {
            this.roleIsSelected(people, role) ? this.deselectWithRole(people, role) : this.selectWithRole(people, role);
        },

        dSearch(){
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(this.findPeople, 250);
        },


        findPeople() {
            let trmQuery = this.peopleQuery.trim();
            let resultGroup = this.getSearchResultGroup();
            resultGroup.members = [];

            if (trmQuery.length > 0) {
                trmQuery = PhidiasTextUtils.removeDiacritics(trmQuery.toLowerCase());

                this.peopleCache.forEach(person => {
                    if(!resultGroup.members.some(foundPerson => foundPerson.id == person.id)){
                        let matches = this.personInQuery(person, trmQuery);
                        if(matches.length > 0){
                            person.matches = matches;
                            resultGroup.members.push(person);
                        }
                    }

                });

                resultGroup.members.sort((a, b) => {
                    return b.matches.length - a.matches.length;
                });

                this.selectedGroup = resultGroup;
            }
        },

        getSearchResultGroup(){
            return this.groups.find(group => group.id == 'searchResult');
        },

        personInQuery(person, query){

            let words = query.split(" ");
            let matches = [];

            words.forEach(word => {
                if(this.txtInTxt(person.filteredFirstname, word) || this.txtInTxt(person.filteredLastname, word)){
                    matches.push(word);
                }
            });

            return matches;
        },

        txtInTxt(subject, query) {
            return subject.includes(query) ? true : false;
        },

        resetSearch(){
            this.peopleQuery = '';
        },

        getPersonFullName(person){
            let fullName = "";

            if(person.hasOwnProperty("firstName") && person.firstName){
                fullName = fullName.concat(person.firstName);

                if(person.hasOwnProperty("lastName") && person.lastName){
                    fullName = fullName.concat(" ").concat(person.lastName);
                }

                if(person.hasOwnProperty("lastName2") && person.lastName2){
                    fullName = fullName.concat(" ").concat(person.lastName2);
                }
            }

            if(person.hasOwnProperty("firstname") && person.firstname){
                fullName = fullName.concat(person.firstname);

                if(person.hasOwnProperty("lastname") && person.lastname){
                    fullName = fullName.concat(" ").concat(person.lastname);
                }

                if(person.hasOwnProperty("lastname2") && person.lastname2){
                    fullName = fullName.concat(" ").concat(person.lastname2);
                }
            }

            if(fullName.length <= 0){
                fullName = "?"
            }

            return fullName;
        },

        deleteSelectedPerson(person, evt){
            let elem = evt.target;
            while(!elem.classList.contains("phi-person-list-item")){
                elem = elem.parentElement;
            }

            let deletedPerson = anime({
                targets: elem,
                scale: 0,
                opacity: 0,
                duration: 250,
                easing: 'easeInOutQuart'
            });

            deletedPerson.complete = () => {this.deselectPerson(person.id)}
        },

        personHaveSelectedRelatives(person){
            let relativesSelected = 0;

            if (person.hasOwnProperty('relatives')) {
                person.relatives.forEach(relative => {
                    if(this.isSelected(relative.id)){
                        relativesSelected += 1;
                    }
                });
            }

            return relativesSelected;
        }
    },

    watch: {
        peopleQuery: function(val) {
            if(val.length == 0){
                this.selectedGroup = this.selectDefaultGroup();
            }
        }
    },

    mounted(){
        this.getGroups();
    }
}
</script>

<template>
    <div class="phi-person-relevance-selector-block">
        <!-- toolbar -->
        <div class="toolbar">
            <div class="left">
                <slot name="top-left"></slot>
            </div>
            <div class="middle">
                <mu-icon
                    class="search-icon"
                    :value="peopleQuery.length > 0 ? 'clear' : 'search'" slot="left"
                    @click="resetSearch"
                />
                <input
                    type="text"
                    :placeholder="$t('search')"
                    v-model="peopleQuery"
                    @input="dSearch()"
                />
            </div>
            <div class="right">
                <mu-icon-menu icon="more_vert" slot="right" :anchorOrigin="rightTop" :targetOrigin="rightTop">
                    <mu-menu-item :title="$t('everyone')" @click="getGroupPeople(selectedGroup).forEach(person => selectPerson(person.id)); getRelatives(getGroupPeople(selectedGroup)).forEach(person => selectPerson(person.id));"/>
                    <mu-menu-item :title="$t('noone')" @click="getGroupPeople(selectedGroup).forEach(person => deselectPerson(person.id)); getRelatives(getGroupPeople(selectedGroup)).forEach(person => deselectPerson(person.id));"/>
                    <mu-menu-item v-for="role in distinctRoles(selectedGroup)" :key="role" :title="$t(role)" @click="toggleWithRole(getGroupPeople(selectedGroup), role)" :rightIcon="roleIsSelected(getGroupPeople(selectedGroup), role) ? 'check_box' : 'check_box_outline_blank'"/>
                </mu-icon-menu>
            </div>
        </div>

        <!-- a simple loading indicator -->
        <mu-linear-progress v-if="fetching"/>

        <!-- tabs representing groups and people selected -->
        <phi-tabs
            v-if="selectedGroup.id != 'searchResult' && !fetching"
            :value="selectedGroup.id"
            @change="selectGroup"
        >
            <template v-for="group in groups">
                <phi-tab
                    :key="group.id"
                    v-if="group.id != 'searchResult'"
                    :value="group.id"
                    :label="group.id == 'r0' ? $t('noun.Relatives') : group.name"
                    :badgeContent="group.id === 'sel' ? selection.length : 0"
                ></phi-tab>
            </template>
        </phi-tabs>

        <!-- people list -->
        <div class="people-list">
            <phi-person-list @personClicked="togglePerson">
                <mu-sub-header v-if="getGroupPeople(selectedGroup).length <= 0">{{fetching ? $t('notice.loading')+"..." : $t('notice.thereIsNothingHere')}}</mu-sub-header>

                <phi-person-list-item
                    v-for="person in getGroupPeople(selectedGroup)"
                    :person="person"
                    :selected="isSelected(person.id) && selectedGroup.id !== 'sel'"
                    :key="person.id"
                    :openedByDefault="false"
                    :showRelativesToggler="person.relatives && person.relatives.length > 0"
                    :class="{ withSelectedReltaives: personHaveSelectedRelatives(person) > 0 }"
                >
                    <span slot="describe">
                        <span class="roles" v-for="(role, key) in person.roles" :key="key">{{ $t(role) }}<span v-if="key < person.roles.length-1">, </span></span>
                    </span>

                    <mu-icon slot="right" :size="30" value="cancel" v-if="selectedGroup.id === 'sel'" @click="deleteSelectedPerson(person, $event)"/>

                    <!-- relatives for this person -->
                    <template v-for="relative in person.relatives">
                        <phi-person-list-item
                            v-if="person.relatives && selectedGroup.id != 'sel'"
                            :person="relative"
                            :selected="isSelected(relative.id) && selectedGroup.id !== 'sel'"
                            :key="relative.id"
                            slot="nested"
                            inset="20px"
                        >
                            <span slot="describe">
                                <span class="roles" v-for="(role, key) in relative.roles" :key="key">{{ $t(role) }}<span v-if="key < relative.roles.length-1">, </span></span>
                            </span>
                        </phi-person-list-item>
                    </template>
                </phi-person-list-item>
            </phi-person-list>
        </div>
    </div>
</template>

<style lang="scss">
.phi-person-relevance-selector-block {
    background-color: #fff;
    height: 100%;
    min-height: 100%;
	display: flex;
	flex-direction: column;

    .people-list {
        flex: 1;
        overflow: hidden;

        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .phi-person-list {
            margin-bottom: 16px;
            padding-bottom: 16px;
        }
    }

    .toolbar {
        display: flex;
        height: 56px;
        max-height: 56px;
        min-height: 56px;
        align-items: center;

        color: #444;

        .left {
            & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 56px;
            }
        }

        .middle {
            flex: 1;
            display: flex;
            align-items: center;

            .mu-icon {
                margin-right: 6px;
                color: #777;
            }

            input {
                flex: 1;
                margin: 0;
                padding: 0;
                font-size: 1em;
                border: 0;
                border-bottom: 1px solid #ccc;
            }
        }

        .right {
            text-align: center;
        }
    }


    div.mu-menu-item.have-left-icon {
        padding-left: 10px !important;
    }

    .mu-linear-progress-indeterminate {
        background-color: var(--phi-color-selected);
    }

    .phi-person-list-item.withSelectedReltaives .mu-item-right i {
        color: var(--phi-color-selected);
    }
}
</style>