<template>
    <div class="phi-event-editor">
        <section class="date-range">
            <date-picker v-model="startDate" :time="!allDay" class="start-date" placeholder="Fecha inicial"></date-picker>
            <span class="until">{{ $t('prep.until') }}</span>
            <date-picker v-model="endDate" :time="!allDay" class="end-date" placeholder="Fecha Final"></date-picker>
        </section>

        <section class="date-options">
            <div>
                <input type="checkbox" id="chbox-allday" v-model="allDay" />
                <label for="chbox-allday">{{ $t('adj.allDay') }}</label>
            </div>
            <div>
                <input type="checkbox" id="chbox-repeats" v-model="isRepeating" />
                <label for="chbox-repeats">{{ $t('adj.repeats') }}</label>
            </div>
        </section>

        <section class="date-repeat" v-if="isRepeating">

            <div class="every">
                <label>{{ $t('adj.repeats') }}</label>
                <select v-model="event.repeat.every">
                    <option value="day">{{ $t('adj.everyDay') }}</option>
                    <option value="week">{{ $t('adj.everyWeek') }}</option>
                    <option value="month">{{ $t('adj.everyMonth') }}</option>
                    <option value="year">{{ $t('adj.everyYear') }}</option>
                </select>
            </div>

            <div v-show="event.repeat.every" class="interval">
                <label>{{ $t('action.repeatEvery') }}</label>
                <select v-model="event.repeat.interval">
                    <option v-for="n in 30" :value="n" :key="n">{{ n }}</option>
                </select>

                <span v-show="event.repeat.every == 'day'">{{ $t('noun.days') }}</span>
                <span v-show="event.repeat.every == 'week'">{{ $t('noun.weeks') }}</span>
                <span v-show="event.repeat.every == 'month'">{{ $t('noun.months') }}</span>
                <span v-show="event.repeat.every == 'year'">{{ $t('noun.years') }}</span>
            </div>

            <div v-show="event.repeat.every == 'week'" class="week">
                <label>{{ $t('noun.day') }}</label>
                <ul>
                    <li @click="toggleDay(1)">
                        <input type="checkbox" id="event-repeat-day-mo" :checked="isDayChecked(1)" />
                        <label for="event-repeat-day-mo">L</label>
                    </li>
                    <li @click="toggleDay(2)">
                        <input type="checkbox" id="event-repeat-day-tu" :checked="isDayChecked(2)" />
                        <label for="event-repeat-day-tu">M</label>
                    </li>
                    <li @click="toggleDay(3)">
                        <input type="checkbox" id="event-repeat-day-we" :checked="isDayChecked(3)" />
                        <label for="event-repeat-day-we">X</label>
                    </li>
                    <li @click="toggleDay(4)">
                        <input type="checkbox" id="event-repeat-day-th" :checked="isDayChecked(4)" />
                        <label for="event-repeat-day-th">J</label>
                    </li>
                    <li @click="toggleDay(5)">
                        <input type="checkbox" id="event-repeat-day-fr" :checked="isDayChecked(5)" />
                        <label for="event-repeat-day-fr">V</label>
                    </li>
                    <li @click="toggleDay(6)">
                        <input type="checkbox" id="event-repeat-day-sa" :checked="isDayChecked(6)" />
                        <label for="event-repeat-day-sa">S</label>
                    </li>
                    <li @click="toggleDay(7)">
                        <input type="checkbox" id="event-repeat-day-su" :checked="isDayChecked(7)" />
                        <label for="event-repeat-day-su">D</label>
                    </li>
                </ul>
            </div>

            <div v-show="event.repeat.every == 'month'" class="month">
                <label>{{ $t('action.repeatOn') }}</label>
                <ul>
                    <li>
                        <input id="event-repeat-month-day" type="radio" value="" v-model="event.repeat.on" />
                        <label for="event-repeat-month-day">{{ $t('noun.dayOfMonth') }}</label>
                    </li>
                    <li>
                        <input id="event-repeat-month-weekday" type="radio" value="weekday" v-model="event.repeat.on" />
                        <label for="event-repeat-month-weekday">{{ $t('noun.dayOfWeek') }}</label>
                    </li>
                </ul>
            </div>

            <div class="until">
                <label>{{ $t('adj.ends') }}</label>
                <date-picker v-model="event.repeat.until" class="until-date" :placeholder="$t('noun.never')"></date-picker>
            </div>

        </section>
    </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';

export default {
    name: "phi-event-editor",
    components: {DatePicker},

    model: {
        prop: 'event'
    },

    props: {
        event: {
            type: Object,
            required: true
        }
    },

    methods: {
        isDayChecked(dayNumber) {
            if (!this.event.repeat || !this.event.repeat.on) {
                return false;
            }

            let eventDay = (new Date(this.startDate * 1000)).getDay();
            eventDay = eventDay == 7 ? 0 : eventDay; // convert to mon-sun:1-7

            return this.event.repeat.on.indexOf(dayNumber) >= 0 || dayNumber == eventDay;
        },

        toggleDay(dayNumber, isChecked) {
            if (this.event.repeat.on.indexOf(dayNumber) >= 0) {
                this.event.repeat.on.splice(this.event.repeat.on.indexOf(dayNumber), 1);
                return;
            }
            this.event.repeat.on.push(dayNumber);
        }
    },

    computed: {
        startDate: {
            get() {
                return typeof this.event.startDate == "object" ? this.event.startDate.getTime()/1000 : this.event.startDate;
            },
            set(incomingDate) {
                this.event.startDate = incomingDate;
                if (incomingDate > this.endDate) {
                    this.endDate = incomingDate;
                }
            }
        },

        endDate: {
            get() {
                return typeof this.event.endDate == "object" ? this.event.endDate.getTime()/1000 : this.event.endDate;
            },
            set(incomingDate) {
                this.event.endDate = incomingDate;
                if (this.startDate > incomingDate) {
                    this.startDate = incomingDate;
                }
            }
        },

        allDay: {
            get() {
                if (!this.event.allDay || this.event.allDay === "0" || this.event.allDay === "false") {
                    return false;
                }

                return true;
            },

            set(allDay) {
                this.event.allDay = !!allDay;
            }
        },

        isRepeating: {
            get() {
                return this.event.repeat != undefined && this.event.repeat != null && this.event.repeat.every != undefined;
            },
            set(doRepeat) {
                this.event.repeat = doRepeat ? {
                    every: "day",
                    interval: 1,
                    on: null,
                    count: null,
                    until: null
                } : null
            }
        }
    },

    watch: {
        'event.repeat.every': function(newValue) {
            if (!this.event.repeat) {
                return;
            }
            this.event.repeat.on = (newValue == "week") ? [] : "";
        }
    }
}
</script>

<style lang="scss">
.phi-event-editor {

    .date-range {
        display: flex;
        align-items: center;

        .until {
            display: block;
            margin: 0 1em;
            text-align: center;
            padding: 3px 0;
        }
    }

    section.date-options {
        display: flex;
        align-items: center;
        padding: 12px 0;

        & > div {
            margin-right: 1em;
        }
    }

    section.date-repeat {
        & > div {
            display: flex;
            align-items: top;
            margin-bottom: 8px;

            & > label {
                display: inline-block;
                font-weight: bold;
                font-size: 0.9em;
                width: 150px;
            }
        }

        .week,
        .month {
            ul {
                list-style: none;
                margin: 0;
                padding: 3px 0;

                display: inline-block;

                li {
                    cursor: pointer;
                    display: inline-block;
                    white-space: nowrap;
                    margin-right: 1em;
                }
            }
        }

        .until {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                display: inline-block;
            }
        }
    }
}
</style>